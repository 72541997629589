.checkout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.checkout-card {
  display: flex;
  flex-direction: column;
  width: 800px;
  margin:20px;
  border: 2px solid #d3d3d3;
  border-radius: 16px;
  background: white;
}

.checkout-card-mobile {
  margin:20px;
}

.logo-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row; 
  align-items: center; 
}

.company-link {
  text-decoration: none;
  font-weight: bold;
  color: #4c51bf;
  margin-left: 5px; 
}

.container-details {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.security-image {
  margin-left: auto;
  width: 400px;
  height: auto;
}



@media (max-width: 768px) {
  .checkout-card {
    width: auto;
    padding: 15px;
    margin: 20px;
    border: none;
  }

  .checkout-container {
    height: auto;
  }

  .container-details {
    flex-direction: column;
  }

  .separator-line {
    width: 100%; 
    height: 4px;
    margin: 20px 0;
  }

  .logo-container {
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    text-align: center; 
  }

  .company-link {
    margin-left: 0; 
    margin-bottom: 10px; 
  }

  .security-image {
    margin-left: 0; 
    margin-top: 10px; 
    height: auto; 
  }
}
