.creditCard-container {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.creditCard {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creditCard-form-header {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccebff;
  padding: 10px;
}

.creditCard-form-header p {
  font-size: 12px;
  font-weight: bold;
}

.creditCard-payment-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}

.divider {
  width: 1px;
  background-color: #3730a3;
  height: 100%;
}

.card-flags {
  margin-top: 10px;
  width: 500px;
  height: auto;
}

.creditCard-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.creditCard-form form {
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-row-inline {
  flex-direction: row;
  justify-content: space-between;
}

.input-row-inline .input-container {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.creditCard-form .input-row label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #3730a3;
}

.input-number,
.input-name,
.input-cpf,
.input-expiry,
.input-cvc {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
}

.submit-button {
  display: flex;
  background-color: #4caf50;
  color: white;
  padding: 7px 10px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button > svg {
  margin-right: 10px;
}

.submit-button:hover {
  background-color: #45a049;
}

.back-button {
  display: flex;
  background-color: #757575;
  color: white;
  padding: 7px 10px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button > svg {
  margin-right: 10px;
}

.back-button:hover {
  background-color: #616161;
}

.error {
  color: red;
  font-size: 0.875em;
}
