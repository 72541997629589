.pix-payment-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
}

.icon-container > svg { 
  color: linear-gradient(to bottom, #6B46C1, #4299E1);
}

.pix-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 48%;
}

.qr-code-text {
  text-align: center;
  margin-bottom: 10px;
}

.qr-code-text h3 {
  color: #3730A3;
}

.qr-code-text p {
  font-size: 18px;
}

.qr-code-container {
  margin-bottom: 20px;
}

.qr-code-image {
  width: 126px;
  height: 126px;
}

.countdown-timer {
  font-size: 18px;
  margin-bottom: 20px;
  color: #3730A3;
}

.pix-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.separator-line-pix {
  width: 4px; 
  height: 100%;
  background: linear-gradient(to bottom, #6B46C1, #4299E1); 
  margin: 0 20px; 
  border-radius: 2px;
}


.pix-code-input {
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  width: 200px;
}

.copy-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #3730A3;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.copy-button:hover {
  background-color: #181181;
}

.copy-button svg {
  margin-right: 5px;
}

.divider {
  width: 1px;
  background-color: #3730A3;
  height: 100%;
  margin: 0 20px;
}

.payment-info {
  width: 48%;
}

.payment-info h3 {
  margin-bottom: 20px;
  color: #3730A3;
  text-align: center
}

.payment-detail {
  margin-bottom: 15px;
  text-align: center
}
.payment-detail p strong {
  font-size: 21px; 
}

.payment-detail p {
  margin: 0;
  font-size: 16px;
}

.pix-image-container {
  padding: 20px;
}

.pix-image {
  height: auto;
}


@media (max-width: 768px) {
  .pix-payment-container {
    flex-direction: column; 
    align-items: center; 
    gap: 2rem;
  }

  .payment-info {
    width: 100%;
  }

  .pix-payment {
    width: 100%;
  }
}