.container-initial-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  width: 100%;
}

.separator-line {
  width: 4px; 
  height: 100%;
  background: linear-gradient(to bottom, #6B46C1, #4299E1); 
  margin: 0 20px; 
  border-radius: 2px;
}

.divider {
  width: 1px;
  background-color: #3730A3;
  height: 100%;
  margin: 0 20px;
}

@media (max-width: 768px) {
  .container-initial-details {
    flex-direction: column; 
    align-items: center; 
    gap: 2rem;
  }
}
